.video-box {
    max-width: 500px;
    font-family: 'Montserrat', sans-serif;
    padding: 16px;
    margin: 8px;
}
.search-alert {
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: #4b84f3;
    font-size: small;
}
ul li {
    color: #212121;
    font-family: "Montserrat", sans-serif;
}
.image-text {
    display: flex;
    gap: 8px;
    max-width: 400px;
}
.image-text img {
    width: 20%;
    object-fit: contain;
} .image-text p {
    width: 80%;
}
.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
  }

  .more-info-image {
    outline: 2px solid grey;
    border-radius: 10px;
    margin-bottom: 16px;
    position: relative;
    max-width: 400px;
  }
  .more-info-image p {
    font-size: small;
    position: absolute;
    height: 100%;
    top: 0;
    margin: 0;
    padding: 4px;
    border-radius: 10px;
    background-color: #fff;
    color: #4b84f3;
    display: none;
    animation: scaleIn 0.3s 1;
  }
  .more-info-image:active p {
    display: block;
  }
  
  .dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }
  
  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
  
  @keyframes dotFlashing {
    0% {
      background-color: #9880ff;
    }
    50%,
    100% {
      background-color: #71FE83;
    }
  }

  .tabs {
    padding: 5px 5px 5px 5px;
    text-align: center;
    font-size: 16px;
    width: 23%;
    color: white;
    background: #666667;
    font-weight: 600;
    cursor: pointer;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  
  .tabs-add {
    padding: 5px 5px 5px 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    width: 23%;
    justify-content: end;
    color: white;
    background: #666667;
    cursor: pointer;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs {
    background: #71FE83;;
    color: #363648 !important;
    border-bottom: 1px solid transparent;
    font-weight: 600;
    animation: tab-up 0.3s;
    bottom: 15px;
    height: 55px;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    border-radius: 5px;
  }

  @keyframes tab-up {
    0% {
        transform: translateY(20%);
    }
    100% {
        transform: translateY(0%);
    }
  }
  
.grid50 {
    display: grid;
    grid-template-columns: 50% 50%;
}

.m-20 {
    margin: 20px;
}

#more-info-gold {
    color: #D0A024;
    font-size: small;
    width: 100%;
}
#more-info-grey{
    color: #666667;
    font-size: small;
    width: 100%;
}
.modal-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 8px;
}
.link-icon {
    height: min(5vw, 22px);
    margin: 4px;
}

.notification-card {
    text-align: left;
    height: auto;
    border-radius: 10px;
    background-color: #99cc00;
    padding: 8px;
    margin: 15px;
    color: #231F20;
}

.notification-card-read {
    text-align: left;
    height: auto;
    border-radius: 10px;
    background-color: #f8f8f8;
    padding: 8px;
    margin: 15px;
    color: #231F20;
}

.flex-center-even {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.lb-ft-row {
    background-color: #1a599c;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr;
    color: #FFFFFF;
    text-align: left;
    padding: 8px;
    font-size: small;
}
.info-blurb {
    background-color: #ffffff;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    border-radius: 10px;
    padding: 8px;
    position: absolute;
    z-index: 100;
    top: 10;
}

.profile-photo {
    height: min(30vw,150px);
    width: min(30vw, 150px);
    object-fit: cover;
    border-radius: 50%;
}

.report-history {
    /* position: absolute; */
    height: 80vh;
    width: 100%;
    z-index: 10;
    background-color: #ffffff;
    border-radius: 10px;
    animation: scaleIn 0.3s 1;
}
.stacked {
    display: grid;
    isolation: isolate;
}

.stacked > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}


a:visited{
   color: #800020 ; 
}
a {
    color: #5096FF;
}
a.contact-link {
    color:#000;
    text-decoration: none;
}
.subtitle {
    font-size: small;
    font-weight: bold;
    color: #666667;
    margin: 0;
    font-family: 'Montserrat' sans-serif;
    padding: 8px;
}

.payment-line-item {
    display: flex;
    justify-content: space-between;
}

.payment-line-item p {
    color: #a7a7a7;
}

#pull-tab {
    background-color: #a7a7a7;
    height: 4px;
    width: 32px;
    border-radius: 3px;
    margin: 12px auto;
}

.button {
    font-size: medium;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    gap:8px;
}

.button-2 {
    font-size: medium;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    border-radius: 10px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    gap:8px;
}

.button:after {
  content: "";
  background: #212121;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px!important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}
.button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }
.button p {
    font-weight: 600;
}
.button ion-icon {
    margin: auto;
    font-size: large;
}
.load-history {
    text-decoration: none;
}
.load-history div {
    background-color: #fff;
    padding: 16px;
    border-radius: 10px;
}

.question-box {
    background-color: #f4f5f8;
    padding: 8px;
    color: 
    #054c5c;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
    width: 100%;
    max-height: auto;
    overflow: hidden;
}
#collapsed {
    display: none;
}
#expanded {
    display: block;
    animation: scaleIn 0.6s 1;
}

#expanded-grid {
    display: grid;
    animation: scaleIn 0.6s 1;
}


.center-box {
    text-align: center;
    background-color: #eeeeee;
    border-radius: 10px;
    padding: 4px;
    color: black;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
    width: 100%;
}
.center-box h5 {
    font-family: "Lato";
    font-weight: 900;
    font-size: x-large;
}

.box-grid {
    display: grid;
    grid-template-columns: 2fr 2fr;
    background-color: #eeeeee;
    border-radius: 10px;
    padding: 4px;
    color: black;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
    width: 100%;
}


.title {
    font-weight: bold;
    font-size: large;
    margin: 0;
    color: black;
    font-family: 'Montserrat' sans-serif;
    padding: 8px;
}
.boldest {
    font-weight: 700;
}
.field-name {
    color: #666667;
}
.notify-badge {
    position: absolute;
    top: 0;
    height: min(8vw, 16px);
    width: min(8vw, 16px);
    display: flex;
    border-radius: 50%;
    background-color: #ff0000;
}
.notify-badge p {
    margin: auto;
    font-family: "Montserrat" sans-serif;
    font-size: small;
    /* font-weight: 600; */
    color: #ffffff;
}
.letter-icon {
    height: min(8vw, 32px);
    width: min(8vw, 32px);
    display: flex;
    border-radius: 50%;
    margin-right: 8px;
}
.letter-icon p{
    margin: auto;
    font-family: "Lato";
    font-size: medium;
    font-weight: 600;
    color: #ffffff;
}
.letter-icon h1 {
    margin: min(2vw, 8px) auto;
    font-family: "Lato";
    font-weight: 600;
    color: #ffffff;
}
.submit {
    width: 100%;
}


.loading-div {
    background-color: #2c2c2c;
    color: #fff;
    font-weight: 600;
    height: auto;
    font-family: "Montserrat", sans-serif;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    overflow: hidden;
}

.warning-div {
    background-color: #e9e9e9;
    color: #212121;
    font-weight: 600;
    height: auto;
    font-family: "Montserrat", sans-serif;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    overflow: hidden;
}

.western-button {
    position: relative;
    /* display: flex; */
    align-items: center;
    /* justify-content: space-between; */
    padding: 12px;
    border-radius: 10px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    gap:8px;
    font-size: medium;
    background-color: #71FE83;
    color: #4c8f2d;
    font-weight: 600;
    height: auto;
    font-family: "Montserrat", sans-serif;
    /* justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    -webkit-transition-duration: 0.4s; 
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 16px; */
}
.western-button:after {
    content: "";
    background: #4c8f2d;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
  }
  .western-button:active:after {
      padding: 0;
      margin: 0;
      opacity: 1;
      transition: 0s
    }

    .western-button-login {
        position: relative;
        /* display: flex; */
        align-items: center;
        /* justify-content: space-between; */
        padding: 1px 6px;
        border-radius: 10px;
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;
        gap:8px;
        font-size: medium;
        background-color: #71FE83;
        color: #4c8f2d;
        font-weight: 600;
        height: auto;
        font-family: "Montserrat", sans-serif;
        /* justify-content: space-between;
        padding: 12px;
        border-radius: 10px;
        -webkit-transition-duration: 0.4s; 
        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 16px; */
    }

    .western-button-cancel {
        position: relative;
        /* display: flex; */
        align-items: center;
        /* justify-content: space-between; */
        padding: 12px;
        border-radius: 10px;
        -webkit-transition-duration: 0.4s; /* Safari */
        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;
        gap:8px;
        font-size: medium;
        background-color: #e41616;
        color: #272727;
        font-weight: 600;
        height: auto;
        font-family: "Montserrat", sans-serif;
        /* justify-content: space-between;
        padding: 12px;
        border-radius: 10px;
        -webkit-transition-duration: 0.4s; 
        transition-duration: 0.4s;
        text-decoration: none;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 16px; */
    }

ion-button#western-button-submit {
    --ion-color-primary: #71FE83;
    background-color: #71FE83;
    border-radius: 10px;
    color: #4c8f2d;
    font-weight: 600;
    height: auto;
    font-family: "Montserrat", sans-serif;
}
ion-button#western-button-small {
    --ion-color-primary: #666667;
    background-color: #666667;
    border-radius: 10px;
    color: #eee;
    font-weight: 400;
    font-size: min(8vw,12px);
}

input[type="checkbox"] {
    transform: scale(1.5);
    color-scheme: light;
}
.input-container {
    height: 50px;
    /* position: relative; */
    width: 100%;
    display: grid;
    place-items: center;
    isolation: isolate;
    position: relative;
    margin: 16px 0;
  }
  .input-container > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}
.input-container > input {
    z-index: -1;
}
.input-container > select:focus{
   height: 300px;
}
  
  .input {
    box-sizing: border-box;
    color: #212121;
    font-size: 18px;
    width: 100%;
    border:none;
    outline: none;
    border-radius: 10px;
    min-height: 48px;
    background-color: #fff;
    color-scheme: light;
  }

  .input-2 {
    box-sizing: border-box;
    color: #212121;
    font-size: 18px;
    width: 100%;
    border: 3px solid #dcdcdc;
    outline: none;
    border-radius: 10px;
    min-height: 48px;
    background-color: #fff;
    color-scheme: light;
  }
  
  /* .cut {
    background-color: inherit;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 76px;
  }
  
  .cut-short {
    width: 50px;
  }
  
  .input:focus ~ .cut,
  .input:not(:placeholder-shown) ~ .cut {
    transform: translateY(8px);
  }
   */

  ion-input#input-text {
    border: 3px solid #dcdcdc;
    border-radius: 10px;
    padding-left: 8px !important;
  }

  .placeholder {
    color: #a7a7a7;
    left: 20px;
    padding: 5px;
    line-height: 14px;
    pointer-events: none;
    width: 100%;
    margin-left: 16px;
    /* position: absolute; */
    transform-origin: 0 50%;
    font-family: "Montserrat" sans-serif;
    transition: transform 200ms, color 200ms;
    top: 20px;
  }
  
  .input:focus ~ .placeholder,
  .input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  }
  
  .input:not(:placeholder-shown) ~ .placeholder {
    color: #808097;
  }
  
  .input:focus ~ .placeholder {
    color: #212121;
  }


#western-form-item input:focus{
    border: 2px solid #a7a7a7;
    outline: none;
    border-radius: 10px;
    min-height: 48px;
}
#western-form-item input{
    
}
#western-form-item div{
    display: grid;
    place-items: center;
    isolation: isolate;
    position: relative;
    padding: 16px;
}
#western-form-item div > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}

#western-form-item div > input {
    z-index: -1;
}
 
#western-form-item div > label {
    width: 100%;
    color: #666667;
    margin-left: 16px;
    font-weight: 600;
    transition: top 0.7s ease-out, left 0.7s ease-out, font-size 0.7s ease-out;
    /* top: 0;
    left: 0; */
}

#western-form-item input:focus + label {
    font-size: 11px;
    position: absolute;
    top: -8px;
    left: 15px;
    margin-left: 0;
    background-color: inherit;
    padding: 0 2px;
    width: auto;
}






.nav-wrapper {
    overflow-x: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    width: 100%;
}

.nav-wrapper::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
.quick-nav {
    display: flex;
    justify-content: space-between;
    list-style: none;
    width: 100%;
    padding-left: 0;
}
.quick-nav li {
    width: 100%;
    color: black;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    margin: 0;
}
hr {
   height: 2px;
   width: 60%;
   background-color: #666667;
   border: none;
   margin-left: 20%;
   transition: all 0.3s ease-in-out; 
}

#scroll-bar {
    margin-left: 0;
}


@media screen and (min-width: 1000px), (orientation: landscape) {
    .main {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .video-gal {
        display: grid;
        grid-template-columns: 33% 33% 33%;
    }
    .video-box {
        width: 20vw;
    }
    .main-30-70 {
        display: grid;
        grid-template-columns: 30% 70%;
    }
    .main-2 {
        display: grid;
    }
    
}

.image-button {
    text-decoration: none;
    margin: 8px;
    display: block;
}

sup {
    font-size: medium;
}

#navi {
    background-color: #212121;
    width: 100%;
    margin: 0;
    padding: 8px;
}
#tooly {
    background-color: #212121;
    width: 100%;
    height: 100%;
    margin: 32px 0 0 0;
    padding:  0 16px;
}

#tooly img {
    width: min(9vw, 34px);
    /* margin: 0 calc(50% - min(9vw, 48px)/2); */
}
#toolbar-right {
    display: flex;
    justify-content: space-evenly;
    width: 40%;
}

.nav-items {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}
.nav-items div {
    margin: 0 auto;
    width: 90%
}
.nav-items p {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    text-align: center;
}
.nav-items div img {
    width: min(9vw, 34px);
    margin: 0 calc(50% - min(9vw, 34px)/2);
}

.block-modal {
    width: 100%;
    --background: transparent;
    display: flex;
    align-items: center;
    --box-shadow: none !important;
    justify-content: center;
}

.image-button div {
    border-radius: 10px;
    width: 100%;
    height: min(30vw, 150px);
    background-size: cover;
    background-repeat: no-repeat;
    color: #000;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
    overflow: hidden;

}

.news-post {
    animation: scaleIn 0.6s 1;
}

.openAnimation {
    animation: animateHeightOpen 0.7s 1;
}

@keyframes scaleIn {
    from {
      transform: scale(0);
      opacity: 0;
    }
  
    to {
      transform: scale(1.0);
      opacity: 1.0;
    }
  }

@keyframes animateHeightOpen {
    0% {
        height: 0%;
    }
    100% {
        height: 80%;
    }
}
  
.image-button div p {
    background-color: #ffffff;
    padding: 8px;
    margin: 0;
}
.image-button div:hover p {
    background-color: #c4c4c4;
}
.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#last-tarp {
    background-image: url('../imgs/tarp.png');
}
#last-tarp:hover {
    transform: scale(1.025);
}
#stock-map {
    background-image: url('../imgs/stock_photo_map.png');
}

.item-container {
    margin: 8px;
    width: 95%;
    font-family: 'Montserrat', sans-serif;
}

.item-container p {
    font-weight: 600;
}
#lb-rank-slide {
    display: flex;
    gap: 8px;
    align-items: baseline;
    justify-content: center;

}

.game-stat {
    text-align: center;
}
.game-stat p, strong {
    margin: 0;
}
.western-button-link {
    display: block;
    text-decoration: none;
    margin: 8px;
}
.western-button-link div {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 4px;
    color: black;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
}
.western-button-link-slide {
    display: block;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 4px;
    width: 100%;
    text-decoration: none;
    margin: 8px;
}
.western-button-link-slide p {
    color: black;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
}
.western-button-link div:hover {
    background-color: #c4c4c4;
}

.western-button-link div.green {
    background-color: #E5F4E0;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
}
.western-button-link div.red {
    background-color: #f4e0e0;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
}
.western-button-link div#current-load-button {
    white-space: nowrap;
    display: flex;
    padding: 0 8px;
    justify-content: space-between;
}
.western-button-link div.outline {
    border: 2px solid #a7a7a7;
    color: #666667;
    padding: 0;
}
.western-button-link div#centered {
    text-align: center;
    display: block;
    height: 100%;
    
}
.western-button-link div#centered img{
    height: min(8vh, 52px);
}
.western-button-link-slide strong, .western-button-link div#centered strong{
    font-size: min(10vw, 36px);
    font-family: "Lato";
    font-weight: 900;
    color: #000000
}

.western-button-link-slide img {
    width: 10vw; 
    max-width: 36px;
}
#gamify-card {
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 4px;
    color: black;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
}

#gamify-card strong{
    font-size: min(10vw, 36px);
    font-family: "Lato";
    font-weight: 900;
}

.link-lead {
    box-shadow: 0 3px 3px rgba(0,0,0,0.25);
    background: #e6f4e1;
    border-radius: 10px;
    margin-top: 10px;
    height: 6vh; 
    color: black;
}

ion-button#training-btn {
    --ion-color-base: white;
    --box-shadow: 0 3px 3px rgba(0,0,0,0.25);
    
}

.training-btn {
    height: 10vh;
    color: black;
    --background-activated: red;
    
}

.down-arrow {
    margin-left: auto;
    font-size: 6vw;
}

.slide {
    text-decoration: none;
}

.swiper-slide {
    font-size: inherit;
}
.slide div {
    text-align: center;
    color: #212121;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 8px;
    color: black;
    font-family: 'Montserrat' sans-serif;
    font-weight: 600;
    min-width: 40vw;
}




iframe#video-modal {
    position: relative;
    margin: -50% auto 0 auto;
    top: 50%;
}

#vid-container {
    display: inline-block;
}

.accident-warning {
    text-align: center;
}

.center {
    text-align: center;
}

.accident-info {
    font-weight: 600 !important;
}

iframe {
    width: 100%;
    border: 0;
}

ion-modal#submit-modal {
    --ion-background-color: rgba(0, 0, 0, 0.3);
}

ion-card#submission-card {
    margin: auto;
    background-color: #c4c4c4;
    margin-left: 2vw;
    margin-right: 2vw;
}

ion-modal#transparent {
    --ion-background-color: rgba(0, 0, 0, 0.3);
}

div#modal {
    margin: auto;
    background-color: #dcdcdc;
    color: #212121;
    border-radius: 10px;
}






ion-input#lp-inspection {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    outline: 1px solid #666666;
    color: #231F20;
    border-radius: 10px;
}

/* p {
    color: black !important;
} */

ion-item {
    color: black;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
}

#tire-diagram {
    width: min(370px, 100vw);
}


ion-toggle {
    --background: rgb(155, 155, 155);
    --background-checked: #5f5f5f;
    --handle-background: #5f5f5f;
    --handle-background-checked: rgb(155, 155, 155);
}

.docbtn {
    background-color: transparent;
    font-size: 10pt;
    color: #99cc00;
    text-decoration: underline;
}

.doc-view {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.trailer-loading {
    background: #222;
    color: #fff;
}

.label-centering {
    width: 100%;
    text-align: center;
}

.field-title {
    color: white;
    padding: 4px;
}

.black {
    color: #262626;
}

.black-button {
    background-color: #262626;
}

.green {
    color: #99cc00;
}

.green-button {
    background-color: #99cc00;
}

.form-header {
    --background: #262626;
}

:root {
   
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    --ion-color-warning: #fff8e2;
    --ion-color-warning-rgb: 255,248,226;
    --ion-color-warning-contrast: #D0A024;
    --ion-color-warning-contrast-rgb: 208, 160, 36;
    --ion-color-warning-shade: #D0A024;
    --ion-color-warning-tint: #ffca22;
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-background-color: #f4f5f8;
    --ion-background-color-rgb: 220, 220, 220;
    --ion-color-dark: #212121;
	--ion-color-dark-rgb: 33,33,33;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #1d1d1d;
	--ion-color-dark-tint: #373737;
    --ion-color-white: #ffffff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #ffffff;
    
}
.ion-color-dark {
	--ion-color-base: var(--ion-color-dark);
	--ion-color-base-rgb: var(--ion-color-dark-rgb);
	--ion-color-contrast: var(--ion-color-dark-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
	--ion-color-shade: var(--ion-color-dark-shade);
	--ion-color-tint: var(--ion-color-dark-tint);
}
.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}





@media (prefers-color-scheme: dark) {
    /*
    * Dark Colors
    * -------------------------------------------
    */
    body {
        --ion-color-success: #2dd36f;
        --ion-color-success-rgb: 45, 211, 111;
        --ion-color-success-contrast: #ffffff;
        --ion-color-success-contrast-rgb: 255, 255, 255;
        --ion-color-success-shade: #28ba62;
        --ion-color-success-tint: #42d77d;
        --ion-color-warning: #fff8e2;
        --ion-color-warning-rgb: 255,248,226;
        --ion-color-warning-contrast: #D0A024;
        --ion-color-warning-contrast-rgb: 208, 160, 36;
        --ion-color-warning-shade: #e0ac08;
        --ion-color-warning-tint: #ffca22;
        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235, 68, 90;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #cf3c4f;
        --ion-color-danger-tint: #ed576b;
      
        --ion-color-light: #f4f5f8;
        --ion-color-light-rgb: 244, 245, 248;
        --ion-color-light-contrast: #000000;
        --ion-color-light-contrast-rgb: 0, 0, 0;
        --ion-color-light-shade: #d7d8da;
        --ion-color-light-tint: #f5f6f9;
        --ion-color-box: #f8f8f8;
    }
}


ion-toolbar {
    font-family: "Montserrat", sans-serif;
}


ion-button#western-button {
    --ion-color-primary: #666666;
    background-color: #666666;
    border-radius: 10px;
    color: #ffffff;
}



ion-button {
    --box-shadow: none;
    /* --padding-start: 0;
    --padding-end: 0; */
}









button#new_message {
    border-radius: 50% !important;
    background-color: #99cc00;
    bottom: 20vh;
    position: absolute;
    width: 60px;
    height: 60px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}
button#new_message ion-icon{
    margin: auto;
    font-size: 24px;
    color: #ffffff;
}


new-message {
    --border-width: 2px;
    --border-radius: 20px;
    position: absolute !important;
    height: 40px;
    margin-left: 28px;
}



/* .leaderboard-button {
    height: 15vw;
    width: 15vw;
    border-radius: 5px;
    background-color: #231F20;
    color: #fff;
    font-family: "Montserrat";
    text-align: center;
    font-weight: 500;
    font-size: 12px;
} */

/* .leaderboard-button img {
    width: 80%;
    margin-top: 3px;
} */

/* .leaderboard-button p {
    margin: 0;
} */




#skeleton-row {
    padding: 8px;
}

.loading-main-text {
    height: 24px;
    width: 100%;
    margin:auto;
    margin-bottom: 10px;
    background: #ececec;
    background-image: -webkit-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
    background-image: -o-linear-gradient(left, #ececec 0px, #ddd 40px, #ececec 80px);
    background-image: linear-gradient(90deg, #ececec 0px, #ddd 40px, #ececec 80px);
    background-size: 250px;
    border-radius: 3px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
            animation: shine-loading-container-items 2s infinite ease-out;
  }
  
@-webkit-keyframes shine-loading-container-items {
    0% {
    background-position: -30vw;
    }
    40%, 100% {
    background-position: 90vw;
    }
}
@keyframes shine-loading-container-items {
    0% {
      background-position: -30vw;
    }
    40%, 100% {
      background-position: 90vw;
    }
  }
/* #leaderboard {
    height: 50vh;
    border-radius: 10px;
    overflow-y: auto;
} */

#leaderboard p {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
}

#leaderboard div {
    background-color: #f8f8f8;
    border-bottom: 2px solid #c4c4c4;
}


/* .leaderboard-content {
    max-height: 40vh;
} */
.leaderboard-card p {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    width: 100%;
}
.signin {
    width: 45vw;
}



.top-three {
    color: #212121;
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.top-three div {
    text-align: center;
}


/* .top-three div:nth-child(1) #leader{
    background-color: #c0c0c0;
   
}
.top-three div:nth-child(3) #leader{
    background-color: #CD7F32;
   
} */
#leader img {
    height: 25px;
    width: 25px;
}
img#ft-icon {
    height: 32px;
    width: 32px;
}
.top-three div:nth-child(2) #leader {
    height: 20vw;
    width: 20vw;
    font-size: 48px;
    /* background-color: #fdc017; */
}

.top-three p {
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 4px 8px;
    margin: 0;
}

.top-three #leader {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    border: 2px solid #a7a7a7;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
}
.my-rank p {
    font-family: "Montserrat";
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    margin: 0px;
}
.my-rank #leader {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    margin: 0;
    font-size: 24px;
    background-color: #ffc107;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 15vw;
    width: 15vw;
    border-radius: 50%;
}

.leaderboard-columns {
    display: grid;
    grid-template-columns: 12% 40% 28% 20%;
    padding: 4px;
}

.leaderboard-row {
    display: grid;
    grid-template-columns: 12% 40% 28% 20%;
    padding: 4px;
    height: 6vh;
    align-items: center;
}
.featured-row {
    height: 8vh;
    background-color: #1a599c;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
    color: #fff;
}
.leader-row {
  color: #000;
  background-color: #eee;
}

.leaderboard-row span {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
}

.feautured-row span {
    color: #fff;
}

.leader-row span {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 2px 8px;
    margin: 0;
    font-size: 12px;
    color: #000000;
}


.ft-leaderboard-row p {
    padding: 2px 8px;
}

.leaderboard-card {
   
    width: 100%;
    background-color: white;
    border-radius: 10px;
    text-align: center;
}

.divider-divider {
    width: calc(90%) !important;
    background: #7c7c7c44 !important;
    margin: 15px;
    height: 1px !important;
    border: 0;
}

ion-segment-button.western-segment-button-green {
    --indicator-color: #71FE83 !important;
    background: #71FE83;
}

ion-segment-button.western-segment-button {
    color: #000;
    background: #dcdcdc;
}

ion-segment-button.western-segment-button-gray {
    --indicator-color: #666666;
    background: #666666;
    color: white !important;
}

@keyframes float {
    0% {
        transform: translate(0, 0px)
    }
    50% {
        transform: translate(0, 15px);
    }
    100% {
        transform: translate(0, 0px);
    }
}
@keyframes span-out-right {
    0% {
        max-width: 0;
    }

    25% {
        max-width: 25%;
    }
    50% {
        max-width: 50%;
    }
    75% {
        max-width: 75%;
    }
    100% {
        max-width: auto;
    }
    
}

@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }
    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }
    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

.login-card {
    background-color: white;
    height: auto;
    padding-bottom: 25px;
    text-align: center;
}

#login-row {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#login-row-consent {
    display: grid;
    grid-template-columns: 90% 10%;
    padding: 4px 0px;
    border-bottom: 1px solid #fff;
    text-align: initial;
}

.LP-Icon-Start {
    width: 85%;
    margin: auto;
    /* animation: moveInTop 0.7s ease-in-out; */
}

.LP-Icon-Header {
    width: 45%;
    margin: auto;
    
    /* animation: moveInTop 0.7s ease-in-out; */
}

/* .Icon-Start-Subheader {
    width: 85%;
    font-weight: 600;
    font-size: 45px;
    color: #9c0;
    font-family: Montserrat;
    line-height: 0px;
    letter-spacing: 8px;
    text-shadow: 0px 0px 25px #FFF;
    margin: auto;
} */

.LP-Icon-Login {
    width: 40%;
    margin: auto auto 25% auto;
}

.LP-Icon-Login-2 {
    width: 75%;
    margin: auto;
}

.sign-up-button-1 {
    clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
    padding: 4%;
    background-color: rgb(225, 255, 133);
    transition: smooth;
    animation: moveInLeft 0.7s ease-in-out;
    -webkit-animation: moveInLeft 0.7s ease-in-out;
    color: #000;
    left: 7%;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    border-radius: 10px 10px 0px;
    position: relative;
}

.sign-up-button-2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
    padding: 4% 0% 4% 7%;
    right: 7%;
    color: #fff;
    animation: moveInRight 0.7s ease-in-out;
    -webkit-animation: moveInRight 0.7s ease-in-out;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    border-radius: 0px 10px 10px;
    background-color: #231f20;
    position: relative;
}

@keyframes moveInLeft{
    0% {
        transform: translateX(-25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 100;
    }
}

@-webkit-keyframes moveInLeft{
    0% {
        transform: translateX(-25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 100;
    }
}

@keyframes moveInRight{
    0% {
        transform: translateX(25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 100;
    }
}

@-webkit-keyframes moveInRight{
    0% {
        transform: translateX(25px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 100;
    }
}

.bg {
    animation:slide 7s ease-in-out infinite alternate;
    background-image: linear-gradient(-20deg, #99cc00 50%, #f4f5f8 50%);
    bottom:0;
    left:-100%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index: -1000;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:7s;
  }
  
  .bg3 {
    animation-duration:10s;
  }
  
  .content {
    background-color:rgba(255,255,255,.8);
    border-radius:.25em;
    box-shadow:0 0 .25em rgba(0,0,0,.25);
    box-sizing:border-box;
    left:50%;
    padding:10vmin;
    position:fixed;
    text-align:center;
    top:50%;
    transform:translate(-50%, -50%);
  }
  

  
  @keyframes slide {
    0% {
      transform:translateX(-15%);
    }
    100% {
      transform:translateX(15%);
    }
  }

/* @keyframes spin {
    0% { transform: rotate3d(0deg);}
    100% { transform: rotate3d(180deg);}
} */



.app-btn {
    white-space: nowrap;
    padding: 10px 20px;
    margin: 4px;
    border-radius: 10px;
    font-size: initial;
    font-weight: 600;
    display: flex;
    transition: background-color .3s;
}

.app-btn[disabled] {
    background-color: #ccc !important;
    color: black !important;
}

.app-btn.app-btn-primary {
    background-color: #E5F4E0;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    color: black;
}

.app-btn.app-btn-primary:hover, .app-btn.app-btn-primary:active {
    background-color: white;
    box-shadow: 0 1px 6px rgba(0,0,0,0.25);
}

.color-danger {
    color: var(--ion-color-danger);
}

.color-black {
    color: black;
}

.w-100 {
    width: 100%;
}

.searchbar-input.sc-ion-searchbar-ios {
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
}

.profile-photo-sm {
    width: 100px;
    height: 100px;
}

.text-right {
    text-align: right !important;
}

.vertical-align-bottom {
    vertical-align: bottom !important;
}

.vertical-align-top {
    vertical-align: top !important;
}


.safety-score-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.safety-score-section-header {
    text-align: center;
    background: #ccc;
    /*background: #99cc00;*/
    width: 100%;
    font-weight: bold;
    padding: 10px 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.safety-score-section-body {
    text-align: center;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flash-light {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 25px 43.3px 25px;
    border-color: transparent transparent #99ff13 transparent;
    transform: rotate(0deg);
}

.userIcon {
    background: #99ff13;
    border-radius: 13px;
    z-index: 10;
    width: 25px;
    height: 25px;
    border: 3.5px solid #f0f0f0;
    /* position: absolute; */
}

.box {
    width: 100px;
    height: 50px;
    top: -36px;
    left: -38px;
    background:radial-gradient(farthest-side at bottom,#99ff13 ,#0000);
    mask:conic-gradient(from -45deg at bottom,#0000, #000 1deg 90deg, #0000 91deg);
    transform-origin: 50% 100%;
    position: absolute;
  }

  .pdf-view {
    overflow-x: auto;
  }

  .pdf-container {
    margin: 10px;
  }