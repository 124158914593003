.dm-card {
    margin: 8px;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
}

.dm-card p {
    font-weight: 600;
}

.dm-card .dm-card-content {
    padding: 5px 0;
    width: 100%;
}

.dm-card .dm-card-header {
    font-size: medium;
    font-weight: bold;
    margin: 4px;
}

.dm-card .dm-card-flavor {
    font-size: small;
    color: #212121;
    background-color: #E5F4E0;
    padding: 4px 8px;
    margin: 0;
    border-radius: 16px;
}

.dm-card .dm-card-sub {
    font-size: small;
    font-weight: bold;
    margin: 4px;
}

.dm-driver-card {
    display: flex;
    padding: 10px;
    transition: background-color .3s;
}

.dm-driver-card:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.dm-driver-card:hover {
    background-color: white;
}

.dm-driver-card-content {
    width: 100%;
    display: flex;
    align-items: center;
}

.top-icon {
    align-self: baseline;
    margin-top: 40px;
}

@media screen and (max-width: 992px) {
    .dm-driver-card {
        display: flex;
        flex-direction: column;
    }

    .dm-driver-card-content {
        display: block;
    }

    .app-btn {
        margin-top: 10px;
    }
}
