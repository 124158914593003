.spinner-background {
    opacity: 0;
}

.spinner-background.active {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, 0.4);
    z-index: 99999;
    opacity: 1;
    transition: opacity .5s;
    transition-delay: .5s;
}

.spinner-background.active .spinner-img, .spinner-background.active .lds-dual-ring {
    display: block;
}

.spinner-img {
    display: none;
    width: 60px;
    left: calc(50% - 30px);
    top: 40%;
    position: fixed;
    z-index: 99999;
    padding-top: 15px;
}

.lds-dual-ring {
    display: none;
    left: calc(50% - 47px);
    top: 40%;
    position: fixed;
    z-index: 99999;
    width: 94px;
    height: 94px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #99cc00 transparent #99cc00 transparent;
    animation: lds-dual-ring 1s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
