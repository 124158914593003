.paycheck-detail-body {
    width: 100%;
    /* min-width: 1400px; */
    max-width: 100%;
    background: white;
    --background: white;
    min-height: 100%;
    padding: 0 60px;
    font-family: 'Courier New', monospace;
    font-size: 17px;
}

.paycheck-detail-body table {
    width: 100%;
    color: #333
}
